import axios from "axios";

const state = {}
const mutations = {}
const getters = {}
const actions = {
    getNijiJadibutiSankalans(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/niji-jadibuti-sankalan`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    createNijiJadibutiSankalan(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/niji-jadibuti-sankalan/create`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    storeNijiJadibutiSankalan(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/niji-jadibuti-sankalan`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    showNijiJadibutiSankalan(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/niji-jadibuti-sankalan/${params.uid}`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    editNijiJadibutiSankalanByUid(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/niji-jadibuti-sankalan/${params.uid}/edit`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateNijiJadibutiSankalan(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/niji-jadibuti-sankalan/${data.uid}?_method=PUT`, data.form)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    deleteNijiJadibutiSankalan(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.delete(`/niji-jadibuti-sankalan/${params}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    getNijiJadibutiSankalanExportView(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/niji-jadibuti-sankalan-export`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateNijiJadibutiSankalanStatus(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/niji-jadibuti-sankalan-status/${data.uid}`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    resendForReviewNijiJadibutiSankalan(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/niji-jadibuti-sankalan-resend/${data.uid}/review`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    nijiJadibutiSankalanReport(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/niji-jadibuti-sankalan-report`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
}
export default {
    state,
    mutations,
    actions,
    getters
}
