import axios from "axios";

const state = {}
const mutations = {}
const getters = {}
const actions = {
    getMonthlyProgresses(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/monthly-program`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    createMonthlyProgress(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/monthly-program/create`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    storeMonthlyProgress(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/monthly-program`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    showMonthlyProgressByUid(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/monthly-program/${data.uid}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    editMonthlyProgressByUid(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/monthly-program/${params.uid}/edit`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateMonthlyProgress(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/monthly-program/${data.uid}?_method=PUT`, data.form)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    deleteMonthlyProgress(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.delete(`/monthly-program/${data}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateMonthlyProgressStatus(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/monthly-program-status/${data.uid}`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    resendForReviewMonthlyProgress(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/monthly-program-resend/${data.uid}/review`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    monthlyProgressReport(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/monthly-program-report`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    }
}
export default {
    state,
    mutations,
    actions,
    getters
}
