import axios from "axios";

const state = {}
const mutations = {}
const getters = {}
const actions = {
    getSthanantaranSahamatis(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/sthanantaran-sahamati`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    createSthanantaranSahamati(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/sthanantaran-sahamati/create`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    storeSthanantaranSahamati(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/sthanantaran-sahamati`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    showSthanantaranSahamati(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/sthanantaran-sahamati/${params.uid}`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    editSthanantaranSahamatiByUid(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/sthanantaran-sahamati/${params.uid}/edit`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateSthanantaranSahamati(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/sthanantaran-sahamati/${data.uid}?_method=PUT`, data.form)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    deleteSthanantaranSahamati(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.delete(`/sthanantaran-sahamati/${params}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    getSthanantaranSahamatiExportView(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/sthanantaran-sahamati-export`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateSthanantaranSahamatiStatus(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/sthanantaran-sahamati-status/${data.uid}`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    resendForReviewSthanantaranSahamati(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/sthanantaran-sahamati-resend/${data.uid}/review`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    sthanantaranSahamatiReport(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/sthanantaran-sahamati-report`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
}
export default {
    state,
    mutations,
    actions,
    getters
}
