import axios from "axios";

const state = {}
const mutations = {}
const getters = {}

const actions = {
    getUsers(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/user`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    createUser(context) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/user/create`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    storeUser(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/user`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    editUser(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/user/${data.username}/edit`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateUser(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/user/${data.username}?_method=PUT`, data.formData)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    deleteUser(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.delete(`/user/${data}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
}
export default {
    state,
    mutations,
    actions,
    getters
}
