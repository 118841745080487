import axios from "axios";

const state = {}
const mutations = {}
const getters = {}
const actions = {
    getSamudayikJadibutiSankalans(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/samudayik-jadibuti-sankalan`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    createSamudayikJadibutiSankalan(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/samudayik-jadibuti-sankalan/create`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    storeSamudayikJadibutiSankalan(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/samudayik-jadibuti-sankalan`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    showSamudayikJadibutiSankalan(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/samudayik-jadibuti-sankalan/${params.uid}`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    editSamudayikJadibutiSankalanByUid(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/samudayik-jadibuti-sankalan/${params.uid}/edit`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateSamudayikJadibutiSankalan(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/samudayik-jadibuti-sankalan/${data.uid}?_method=PUT`, data.form)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    deleteSamudayikJadibutiSankalan(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.delete(`/samudayik-jadibuti-sankalan/${params}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    getSamudayikJadibutiSankalanExportView(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/samudayik-jadibuti-sankalan-export`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateSamudayikJadibutiSankalanStatus(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/samudayik-jadibuti-sankalan-status/${data.uid}`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    resendForReviewSamudayikJadibutiSankalan(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/samudayik-jadibuti-sankalan-resend/${data.uid}/review`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    samudayikJadibutiSankalanReport(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/samudayik-jadibuti-sankalan-report`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
}
export default {
    state,
    mutations,
    actions,
    getters
}
