import axios from "axios";

const state = {}
const mutations = {}
const getters = {}
const actions = {
    getForestSurveys(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/forest-survey`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    createForestSurvey(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/forest-survey/create`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    storeForestSurvey(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/forest-survey`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    showForestSurvey(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/forest-survey/${params.id}`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    editForestSurvey(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/forest-survey/${params.id}/edit`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateForestSurvey(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/forest-survey/${data.id}?_method=PUT`, data.form)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    deleteForestSurvey(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.delete(`/forest-survey/${data}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    getForestSurveyExportView(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/forest-survey-export`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateForestSurveyStatus(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/forest-survey-status/${data.id}`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    resendForReviewForestSurvey(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/forest-survey-resend/${data.id}/review`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
}
export default {
    state,
    mutations,
    actions,
    getters
}
