import axios from "axios";

const state = {}
const mutations = {}
const getters = {}
const actions = {
    getNijiKathDauraNikasis(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/niji-kath-daura-nikasi`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    createNijiKathDauraNikasi(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/niji-kath-daura-nikasi/create`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    storeNijiKathDauraNikasi(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/niji-kath-daura-nikasi`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    showNijiKathDauraNikasi(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/niji-kath-daura-nikasi/${params.uid}`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    editNijiKathDauraNikasiByUid(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/niji-kath-daura-nikasi/${params.uid}/edit`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateNijiKathDauraNikasi(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/niji-kath-daura-nikasi/${data.uid}?_method=PUT`, data.form)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    deleteNijiKathDauraNikasi(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.delete(`/niji-kath-daura-nikasi/${params}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    getNijiKathDauraNikasiExportView(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/niji-kath-daura-nikasi-export`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateNijiKathDauraNikasiStatus(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/niji-kath-daura-nikasi-status/${data.uid}`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    resendForReviewNijiKathDauraNikasi(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/niji-kath-daura-nikasi-resend/${data.uid}/review`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    nijiKathDauraNikasiReport(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/niji-kath-daura-nikasi-report`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
}
export default {
    state,
    mutations,
    actions,
    getters
}
