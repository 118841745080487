import axios from "axios";

const state = {}
const mutations = {}
const getters = {}
const actions = {
    getNijiJadibutiNikasis(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/niji-jadibuti-nikasi`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    createNijiJadibutiNikasi(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/niji-jadibuti-nikasi/create`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    storeNijiJadibutiNikasi(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/niji-jadibuti-nikasi`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    showNijiJadibutiNikasi(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/niji-jadibuti-nikasi/${params.uid}`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    editNijiJadibutiNikasiByUid(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/niji-jadibuti-nikasi/${params.uid}/edit`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateNijiJadibutiNikasi(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/niji-jadibuti-nikasi/${data.uid}?_method=PUT`, data.form)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    deleteNijiJadibutiNikasi(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.delete(`/niji-jadibuti-nikasi/${params}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    getNijiJadibutiNikasiExportView(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/niji-jadibuti-nikasi-export`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateNijiJadibutiNikasiStatus(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/niji-jadibuti-nikasi-status/${data.uid}`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    resendForReviewNijiJadibutiNikasi(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/niji-jadibuti-nikasi-resend/${data.uid}/review`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    nijiJadibutiNikasiReport(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/niji-jadibuti-nikasi-report`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
}
export default {
    state,
    mutations,
    actions,
    getters
}
