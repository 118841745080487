import axios from "axios";

const state = {}
const mutations = {}
const getters = {}
const actions = {
    getSamudayikKhotoNikasis(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/samudayik-khoto-nikasi`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    createSamudayikKhotoNikasi(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/samudayik-khoto-nikasi/create`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    storeSamudayikKhotoNikasi(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/samudayik-khoto-nikasi`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    showSamudayikKhotoNikasi(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/samudayik-khoto-nikasi/${params.uid}`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    editSamudayikKhotoNikasiByUid(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/samudayik-khoto-nikasi/${params.uid}/edit`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateSamudayikKhotoNikasi(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/samudayik-khoto-nikasi/${data.uid}?_method=PUT`, data.form)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    deleteSamudayikKhotoNikasi(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.delete(`/samudayik-khoto-nikasi/${params}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    getSamudayikKhotoNikasiExportView(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/samudayik-khoto-nikasi-export`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateSamudayikKhotoNikasiStatus(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/samudayik-khoto-nikasi-status/${data.uid}`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    resendForReviewSamudayikKhotoNikasi(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/samudayik-khoto-nikasi-resend/${data.uid}/review`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    samudayikKhotoNikasiReport(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/samudayik-khoto-nikasi-report`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
}
export default {
    state,
    mutations,
    actions,
    getters
}
