import axios from "axios";

const state = {}
const mutations = {}
const getters = {}
const actions = {
    getKathDauras(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/kath-daura`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    createKathDaura(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/kath-daura/create`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    storeKathDaura(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/kath-daura`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    showKathDaura(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/kath-daura/${params.uid}`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    editKathDauraByUid(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/kath-daura/${params.uid}/edit`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateKathDaura(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/kath-daura/${data.uid}?_method=PUT`, data.form)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    deleteKathDaura(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.delete(`/kath-daura/${params}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    getKathDauraExportView(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/kath-daura-export`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateKathDauraStatus(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/kath-daura-status/${data.uid}`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    resendForReviewKathDaura(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/kath-daura-resend/${data.uid}/review`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    kathDauraReport(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/kath-daura-report`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    importKathDaura(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/kath-daura-import`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
}
export default {
    state,
    mutations,
    actions,
    getters
}
