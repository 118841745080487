import axios from "axios";

const state = {}
const mutations = {}
const getters = {}
const actions = {
    getCaseDetails(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/case-detail`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    createCaseDetail(context, params) {
        console.log(params)
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/case-detail/create`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    storeCaseDetail(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/case-detail`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    showCaseDetail(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/case-detail/${params.uid}`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    editCaseDetailByUid(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/case-detail/${params.uid}/edit`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateCaseDetail(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/case-detail/${data.uid}?_method=PUT`, data.form)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    deleteCaseDetail(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.delete(`/case-detail/${params}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    getCaseDetailExportView(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/case-detail-export`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateCaseDetailStatus(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/case-detail-status/${data.uid}`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    resendForReviewCaseDetail(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/case-detail-resend/${data.uid}/review`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    caseDetailReport(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/case-detail-report`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    importCaseDetail(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/case-detail-import`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
}
export default {
    state,
    mutations,
    actions,
    getters
}
