import axios from "axios";

const state = {}
const mutations = {}
const getters = {}
const actions = {
    getSarkariKathDauraKatans(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/sarkari-kath-daura-katan`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    createSarkariKathDauraKatan(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/sarkari-kath-daura-katan/create`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    storeSarkariKathDauraKatan(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/sarkari-kath-daura-katan`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    showSarkariKathDauraKatan(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/sarkari-kath-daura-katan/${params.uid}`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    editSarkariKathDauraKatanByUid(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/sarkari-kath-daura-katan/${params.uid}/edit`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateSarkariKathDauraKatan(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/sarkari-kath-daura-katan/${data.uid}?_method=PUT`, data.form)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    deleteSarkariKathDauraKatan(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.delete(`/sarkari-kath-daura-katan/${params}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    getSarkariKathDauraKatanExportView(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/sarkari-kath-daura-katan-export`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateSarkariKathDauraKatanStatus(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/sarkari-kath-daura-katan-status/${data.uid}`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    resendForReviewSarkariKathDauraKatan(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/sarkari-kath-daura-katan-resend/${data.uid}/review`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    sarkariKathDauraKatanReport(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/sarkari-kath-daura-katan-report`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
}
export default {
    state,
    mutations,
    actions,
    getters
}
