import axios from "axios";

const state = {}
const mutations = {}
const getters = {}
const actions = {
    getSamudayikKhotoSankalans(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/samudayik-khoto-sankalan`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    createSamudayikKhotoSankalan(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/samudayik-khoto-sankalan/create`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    storeSamudayikKhotoSankalan(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/samudayik-khoto-sankalan`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    showSamudayikKhotoSankalan(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/samudayik-khoto-sankalan/${params.uid}`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    editSamudayikKhotoSankalanByUid(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/samudayik-khoto-sankalan/${params.uid}/edit`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateSamudayikKhotoSankalan(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/samudayik-khoto-sankalan/${data.uid}?_method=PUT`, data.form)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    deleteSamudayikKhotoSankalan(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.delete(`/samudayik-khoto-sankalan/${params}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    getSamudayikKhotoSankalanExportView(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/samudayik-khoto-sankalan-export`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateSamudayikKhotoSankalanStatus(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/samudayik-khoto-sankalan-status/${data.uid}`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    resendForReviewSamudayikKhotoSankalan(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/samudayik-khoto-sankalan-resend/${data.uid}/review`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    samudayikKhotoSankalanReport(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/samudayik-khoto-sankalan-report`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
}
export default {
    state,
    mutations,
    actions,
    getters
}
