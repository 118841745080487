import axios from "axios";

const state = {}
const mutations = {}
const getters = {}

const actions = {
    getFeedbacks(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/feedback`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    createFeedback(context,params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/feedback/create`,{params:params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    storeFeedback(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/feedback`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    showFeedback(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/feedback/${data}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    editFeedback(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/feedback/${data}/edit`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateFeedback(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.put(`/feedback/${data.id}`, data.formData)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    deleteFeedback(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.delete(`/feedback/${data}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    extractFeedback(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/feedback-import`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
}
export default {
    state,
    mutations,
    actions,
    getters
}
