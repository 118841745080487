import axios from "axios";

const state = {}
const mutations = {}
const getters = {}
const actions = {
    getNijiKathDauraKatanis(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/niji-kath-daura-katani`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    createNijiKathDauraKatani(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/niji-kath-daura-katani/create`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    storeNijiKathDauraKatani(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/niji-kath-daura-katani`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    showNijiKathDauraKatani(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/niji-kath-daura-katani/${params.uid}`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    editNijiKathDauraKataniByUid(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/niji-kath-daura-katani/${params.uid}/edit`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateNijiKathDauraKatani(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/niji-kath-daura-katani/${data.uid}?_method=PUT`, data.form)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    deleteNijiKathDauraKatani(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.delete(`/niji-kath-daura-katani/${params}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    getNijiKathDauraKataniExportView(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/niji-kath-daura-katani-export`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateNijiKathDauraKataniStatus(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/niji-kath-daura-katani-status/${data.uid}`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    resendForReviewNijiKathDauraKatani(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/niji-kath-daura-katani-resend/${data.uid}/review`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    nijiKathDauraKataniReport(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/niji-kath-daura-katani-report`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
}
export default {
    state,
    mutations,
    actions,
    getters
}
