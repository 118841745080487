import axios from "axios";

const state = {}
const mutations = {}
const getters = {}
const actions = {
    getAabadiChhodpurjis(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/aabadi-chhodpurji`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    createAabadiChhodpurji(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/aabadi-chhodpurji/create`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    storeAabadiChhodpurji(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/aabadi-chhodpurji`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    showAabadiChhodpurji(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/aabadi-chhodpurji/${params.uid}`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    editAabadiChhodpurjiByUid(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/aabadi-chhodpurji/${params.uid}/edit`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateAabadiChhodpurji(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/aabadi-chhodpurji/${data.uid}?_method=PUT`, data.form)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    deleteAabadiChhodpurji(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.delete(`/aabadi-chhodpurji/${params}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    getAabadiChhodpurjiExportView(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/aabadi-chhodpurji-export`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateAabadiChhodpurjiStatus(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/aabadi-chhodpurji-status/${data.uid}`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    resendForReviewAabadiChhodpurji(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/aabadi-chhodpurji-resend/${data.uid}/review`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    aabadiChhodpurjiReport(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/aabadi-chhodpurji-report`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
}
export default {
    state,
    mutations,
    actions,
    getters
}
