import axios from "axios";
import i18n from "../../i18n";

const state = {
    locale: i18n.locale,
    logo: '',
    user: {},
    showFullLoader: false,
    currentMonth: null,
    currentFiscalYear: null,
    client_id: null,
    navigations: [
        {
            icon: "bx bxs-dashboard",
            id: 0,
            label: "menuitems.dashboard.text",
            name: "dashboard",
            navigation: "/dashboard",
            subItems: [],
            roles: [1, 2, 3, 4, 5, 7, 8, 9, 10, 11, 12, 13],
        },
        {
            "id": 23,
            "name": "cfug",
            "label": "menuitems.cfug.text",
            "icon": "mdi mdi-pine-tree",
            "navigation": "/cfug",
            "permissions": [
                "cfug-create",
                "cfug-read",
                "cfug-update",
                "cfug-delete",
                "cfug-export",
                "cfug-export-excel",
                "cfug-export-by-district",
                "cfug-status"
            ],
            "subItems": [],
            "roles": [1, 2, 4, 7, 8, 9, 10, 11, 12, 13],
        },
        {
            "id": 79,
            "name": "Samudayik Ban Paidawar Bibaran",
            "label": "menuitems.sbpb.text",
            "icon": "mdi mdi-grass",
            "navigation": "/",
            "permissions": [],
            "roles": [1, 2, 4, 7, 8, 9, 10, 11, 12, 13],
            "subItems": [
                {
                    "id": 80,
                    "name": "lilam-sahamati",
                    "label": "menuitems.sbpb.subItems.lilamSahamati",
                    "icon": null,
                    "navigation": "/lilam-sahamati",
                    "permissions": [
                        "lilam-sahamati-create",
                        "lilam-sahamati-read",
                        "lilam-sahamati-update",
                        "lilam-sahamati-delete",
                        "lilam-sahamati-export",
                        "lilam-sahamati-export-excel",
                        "lilam-sahamati-export-by-district",
                        "lilam-sahamati-status"
                    ]
                },
                {
                    "id": 81,
                    "name": "samudayik-kath-daura-sankalan",
                    "label": "menuitems.sbpb.subItems.samudayikKathDauraSankalan",
                    "icon": null,
                    "navigation": "/samudayik-kath-daura-sankalan",
                    "permissions": [
                        "samudayik-kath-daura-sankalan-create",
                        "samudayik-kath-daura-sankalan-update",
                        "samudayik-kath-daura-sankalan-read",
                        "samudayik-kath-daura-sankalan-delete",
                        "samudayik-kath-daura-sankalan-export",
                        "samudayik-kath-daura-sankalan-export-excel",
                        "samudayik-kath-daura-sankalan-export-by-district",
                        "samudayik-kath-daura-sankalan-status"
                    ]
                },
                {
                    "id": 82,
                    "name": "lilam-bikri",
                    "label": "menuitems.sbpb.subItems.lilamBikri",
                    "icon": null,
                    "navigation": "/lilam-bikri",
                    "permissions": [
                        "lilam-bikri-create",
                        "lilam-bikri-read",
                        "lilam-bikri-update",
                        "lilam-bikri-delete",
                        "lilam-bikri-export",
                        "lilam-bikri-export-excel",
                        "lilam-bikri-export-by-district",
                        "lilam-bikri-status"
                    ]
                },
                {
                    "id": 83,
                    "name": "sthanantaran-sahamati",
                    "label": "menuitems.sbpb.subItems.sthanantaranSahamati",
                    "icon": null,
                    "navigation": "/sthanantaran-sahamati",
                    "permissions": [
                        "sthanantaran-sahamati-create",
                        "sthanantaran-sahamati-read",
                        "sthanantaran-sahamati-update",
                        "sthanantaran-sahamati-delete",
                        "sthanantaran-sahamati-export",
                        "sthanantaran-sahamati-export-excel",
                        "sthanantaran-sahamati-export-by-district",
                        "sthanantaran-sahamati-status"
                    ]
                },
                {
                    "id": 84,
                    "name": "samudayik-kath-daura-nikasi",
                    "label": "menuitems.sbpb.subItems.samudayikKathDauraNikasi",
                    "icon": null,
                    "navigation": "/samudayik-kath-daura-nikasi",
                    "permissions": [
                        "samudayik-kath-daura-nikasi-create",
                        "samudayik-kath-daura-nikasi-read",
                        "samudayik-kath-daura-nikasi-update",
                        "samudayik-kath-daura-nikasi-delete",
                        "samudayik-kath-daura-nikasi-export",
                        "samudayik-kath-daura-nikasi-export-excel",
                        "samudayik-kath-daura-nikasi-export-by-district",
                        "samudayik-kath-daura-nikasi-status"
                    ]
                },
                {
                    "id": 85,
                    "name": "samudayik-jadibuti-sankalan",
                    "label": "menuitems.sbpb.subItems.samudayikJadibutiSankalan",
                    "icon": null,
                    "navigation": "/samudayik-jadibuti-sankalan",
                    "permissions": [
                        "samudayik-jadibuti-sankalan-create",
                        "samudayik-jadibuti-sankalan-read",
                        "samudayik-jadibuti-sankalan-update",
                        "samudayik-jadibuti-sankalan-delete",
                        "samudayik-jadibuti-sankalan-export",
                        "samudayik-jadibuti-sankalan-export-excel",
                        "samudayik-jadibuti-sankalan-export-by-district",
                        "samudayik-jadibuti-sankalan-status"
                    ]
                },
                {
                    "id": 86,
                    "name": "samudayik-jadibuti-nikasi",
                    "label": "menuitems.sbpb.subItems.samudayikJadibutiNikasi",
                    "icon": null,
                    "navigation": "/samudayik-jadibuti-nikasi",
                    "permissions": [
                        "samudayik-jadibuti-nikasi-create",
                        "samudayik-jadibuti-nikasi-read",
                        "samudayik-jadibuti-nikasi-update",
                        "samudayik-jadibuti-nikasi-delete",
                        "samudayik-jadibuti-nikasi-export",
                        "samudayik-jadibuti-nikasi-export-excel",
                        "samudayik-jadibuti-nikasi-export-by-district",
                        "samudayik-jadibuti-nikasi-status"
                    ]
                },
                {
                    "id": 87,
                    "name": "samudayik-khoto-sankalan",
                    "label": "menuitems.sbpb.subItems.samudayikKhotoSankalan",
                    "icon": null,
                    "navigation": "/samudayik-khoto-sankalan",
                    "permissions": [
                        "samudayik-khoto-sankalan-create",
                        "samudayik-khoto-sankalan-read",
                        "samudayik-khoto-sankalan-update",
                        "samudayik-khoto-sankalan-delete",
                        "samudayik-khoto-sankalan-export",
                        "samudayik-khoto-sankalan-export-excel",
                        "samudayik-khoto-sankalan-export-by-district",
                        "samudayik-khoto-sankalan-status"
                    ]
                },
                {
                    "id": 88,
                    "name": "samudayik-khoto-nikasi",
                    "label": "menuitems.sbpb.subItems.samudayikKhotoNikasi",
                    "icon": null,
                    "navigation": "/samudayik-khoto-nikasi",
                    "permissions": [
                        "samudayik-khoto-nikasi-create",
                        "samudayik-khoto-nikasi-read",
                        "samudayik-khoto-nikasi-update",
                        "samudayik-khoto-nikasi-delete",
                        "samudayik-khoto-nikasi-export",
                        "samudayik-khoto-nikasi-export-excel",
                        "samudayik-khoto-nikasi-export-by-district",
                        "samudayik-khoto-nikasi-status"
                    ]
                }
            ]
        },
        {
            "id": 89,
            "name": "Niji Ban Paidawar Bibaran",
            "label": "menuitems.nbpb.text",
            "icon": "mdi mdi-pine-tree",
            "navigation": "/",
            "permissions": [],
            "roles": [1, 2, 7, 8, 9, 10, 11, 12],
            "subItems": [
                {
                    "id": 78,
                    "name": "niji-jadibuti",
                    "label": "menuitems.nijiJadibuti.text",
                    "icon": null,
                    "navigation": "/niji-jadibuti",
                    "permissions": [
                        "niji-jadibuti-create",
                        "niji-jadibuti-read",
                        "niji-jadibuti-update",
                        "niji-jadibuti-delete",
                        "niji-jadibuti-export",
                        "niji-jadibuti-export-excel",
                        "niji-jadibuti-export-by-district",
                        "niji-jadibuti-status"
                    ]
                },
                {
                    "id": 90,
                    "name": "niji-kath-daura-katani",
                    "label": "menuitems.nbpb.subItems.nijiKathDauraKatan",
                    "icon": null,
                    "navigation": "/niji-kath-daura-katani",
                    "permissions": [
                        "niji-kath-daura-katani-create",
                        "niji-kath-daura-katani-read",
                        "niji-kath-daura-katani-update",
                        "niji-kath-daura-katani-delete",
                        "niji-kath-daura-katani-export",
                        "niji-kath-daura-katani-export-excel",
                        "niji-kath-daura-katani-export-by-district",
                        "niji-kath-daura-katani-status"
                    ]
                },
                {
                    "id": 91,
                    "name": "niji-kath-daura-nikasi",
                    "label": "menuitems.nbpb.subItems.nijiKathDauraNikasi",
                    "icon": null,
                    "navigation": "/niji-kath-daura-nikasi",
                    "permissions": [
                        "niji-kath-daura-nikasi-create",
                        "niji-kath-daura-nikasi-read",
                        "niji-kath-daura-nikasi-update",
                        "niji-kath-daura-nikasi-delete",
                        "niji-kath-daura-nikasi-export",
                        "niji-kath-daura-nikasi-export-excel",
                        "niji-kath-daura-nikasi-export-by-district",
                        "niji-kath-daura-nikasi-status"
                    ]
                },
                {
                    "id": 92,
                    "name": "niji-jadibuti-sankalan",
                    "label": "menuitems.nbpb.subItems.nijiJadibutiSankalan",
                    "icon": null,
                    "navigation": "/niji-jadibuti-sankalan",
                    "permissions": [
                        "niji-jadibuti-sankalan-create",
                        "niji-jadibuti-sankalan-read",
                        "niji-jadibuti-sankalan-update",
                        "niji-jadibuti-sankalan-delete",
                        "niji-jadibuti-sankalan-export",
                        "niji-jadibuti-sankalan-export-excel",
                        "niji-jadibuti-sankalan-export-by-district",
                        "niji-jadibuti-sankalan-status"
                    ]
                },
                {
                    "id": 93,
                    "name": "niji-jadibuti-nikasi",
                    "label": "menuitems.nbpb.subItems.nijiJadibutiNikasi",
                    "icon": null,
                    "navigation": "/niji-jadibuti-nikasi",
                    "permissions": [
                        "niji-jadibuti-nikasi-create",
                        "niji-jadibuti-nikasi-read",
                        "niji-jadibuti-nikasi-update",
                        "niji-jadibuti-nikasi-delete",
                        "niji-jadibuti-nikasi-export",
                        "niji-jadibuti-nikasi-export-excel",
                        "niji-jadibuti-nikasi-export-by-district",
                        "niji-jadibuti-nikasi-status"
                    ]
                }
            ]
        },
        {
            "id": 94,
            "name": "Sarkari Management",
            "label": "menuitems.smbp.text",
            "icon": "mdi mdi-grass",
            "navigation": "/",
            "permissions": [],
            "roles": [1, 2, 3, 7, 8, 9, 10, 11, 12],
            "subItems": [
                {
                    "id": 95,
                    "name": "sarkari-kath-daura-chhodpurji",
                    "label": "menuitems.smbp.subItems.sarkariKathDauraChhodpurji",
                    "icon": null,
                    "navigation": "/sarkari-kath-daura-chhodpurji",
                    "permissions": [
                        "sarkari-kath-daura-chhodpurji-create",
                        "sarkari-kath-daura-chhodpurji-read",
                        "sarkari-kath-daura-chhodpurji-update",
                        "sarkari-kath-daura-chhodpurji-delete",
                        "sarkari-kath-daura-chhodpurji-export",
                        "sarkari-kath-daura-chhodpurji-export-excel",
                        "sarkari-kath-daura-chhodpurji-export-by-district",
                        "sarkari-kath-daura-chhodpurji-status"
                    ]
                },
                {
                    "id": 96,
                    "name": "sarkari-jadibuti-sankalan",
                    "label": "menuitems.smbp.subItems.sarkariJadibutiSankalan",
                    "icon": null,
                    "navigation": "/sarkari-jadibuti-sankalan",
                    "permissions": [
                        "sarkari-jadibuti-sankalan-create",
                        "sarkari-jadibuti-sankalan-read",
                        "sarkari-jadibuti-sankalan-update",
                        "sarkari-jadibuti-sankalan-delete",
                        "sarkari-jadibuti-sankalan-export",
                        "sarkari-jadibuti-sankalan-export-excel",
                        "sarkari-jadibuti-sankalan-export-by-district",
                        "sarkari-jadibuti-sankalan-status"
                    ]
                },
                {
                    "id": 97,
                    "name": "sarkari-jadibuti-chhodpurji",
                    "label": "menuitems.smbp.subItems.sarkariJadibutiChhodpurji",
                    "icon": null,
                    "navigation": "/sarkari-jadibuti-chhodpurji",
                    "permissions": [
                        "sarkari-jadibuti-chhodpurji-create",
                        "sarkari-jadibuti-chhodpurji-read",
                        "sarkari-jadibuti-chhodpurji-update",
                        "sarkari-jadibuti-chhodpurji-delete",
                        "sarkari-jadibuti-chhodpurji-export",
                        "sarkari-jadibuti-chhodpurji-export-excel",
                        "sarkari-jadibuti-chhodpurji-export-by-district",
                        "sarkari-jadibuti-chhodpurji-status"
                    ]
                }
            ]
        },
        {
            "id": 101,
            "name": "Sarkari Ruak Katan",
            "label": "menuitems.srk.text",
            "icon": "mdi mdi-pine-tree",
            "navigation": "/",
            "permissions": [],
            "roles": [1, 2, 7],
            "subItems": [
                {
                    "id": 102,
                    "name": "sarkari-kath-daura-katan",
                    "label": "menuitems.srk.subItems.sarkariKathDauraKatan",
                    "icon": null,
                    "navigation": "/sarkari-kath-daura-katan",
                    "permissions": [
                        "sarkari-kath-daura-katan-create",
                        "sarkari-kath-daura-katan-read",
                        "sarkari-kath-daura-katan-update",
                        "sarkari-kath-daura-katan-delete",
                        "sarkari-kath-daura-katan-export",
                        "sarkari-kath-daura-katan-export-excel",
                        "sarkari-kath-daura-katan-export-by-district",
                        "sarkari-kath-daura-katan-status"
                    ]
                }
            ]
        },
        {
            "id": 98,
            "name": "Niji Aaabadi Ban Pidabar",
            "label": "menuitems.nabp.text",
            "icon": "mdi mdi-pine-tree",
            "navigation": "/",
            "permissions": [],
            "roles": [1, 2, 7],
            "subItems": [
                {
                    "id": 99,
                    "name": "aabadi-chhodpurji",
                    "label": "menuitems.nabp.subItems.aabadiChhodpurji",
                    "icon": null,
                    "navigation": "/aabadi-chhodpurji",
                    "permissions": [
                        "aabadi-chhodpurji-create",
                        "aabadi-chhodpurji-read",
                        "aabadi-chhodpurji-update",
                        "aabadi-chhodpurji-delete",
                        "aabadi-chhodpurji-export",
                        "aabadi-chhodpurji-export-excel",
                        "aabadi-chhodpurji-export-by-district",
                        "aabadi-chhodpurji-status"
                    ]
                },
                {
                    "id": 100,
                    "name": "aabadi-katan",
                    "label": "menuitems.nabp.subItems.aabadiKatan",
                    "icon": null,
                    "navigation": "/aabadi-katan",
                    "permissions": [
                        "aabadi-katan-create",
                        "aabadi-katan-read",
                        "aabadi-katan-update",
                        "aabadi-katan-delete",
                        "aabadi-katan-export",
                        "aabadi-katan-export-excel",
                        "aabadi-katan-export-by-district",
                        "aabadi-katan-status"
                    ]
                }
            ]
        },
        {
            "id": 103,
            "name": "Employee Department",
            "label": "menuitems.ed.text",
            "icon": "mdi mdi-account-group",
            "navigation": "/",
            "roles": [1, 2, 4, 5, 7],
            "permissions": [
                "employee-detail-create",
                "employee-detail-read",
                "employee-detail-update",
                "employee-detail-delete",
                "employee-detail-export",
                "employee-detail-export-excel",
                "employee-detail-export-by-district",
                "employee-detail-status"
            ],
            "subItems": [
                {
                    "id": 104,
                    "name": "employee-detail",
                    "label": "menuitems.ed.subItems.employeeDetail",
                    "icon": null,
                    "navigation": "/employee-detail",
                    "permissions": [
                        "employee-detail-create",
                        "employee-detail-read",
                        "employee-detail-update",
                        "employee-detail-delete",
                        "employee-detail-export",
                        "employee-detail-export-excel",
                        "employee-detail-export-by-district",
                        "employee-detail-status"
                    ]
                },
                {
                    "id": 105,
                    "name": "leave",
                    "label": "menuitems.ed.subItems.leave",
                    "icon": null,
                    "navigation": "/leave",
                    "permissions": [
                        "leave-create",
                        "leave-read",
                        "leave-update",
                        "leave-delete",
                        "leave-export",
                        "leave-export-excel",
                        "leave-export-by-district",
                        "leave-status"
                    ]
                },
                {
                    "id": 107,
                    "name": "fifteen-detail",
                    "label": "menuitems.ed.subItems.fifteenDetail",
                    "icon": null,
                    "navigation": "/fifteen-detail",
                    "permissions": [
                        "fifteen-detail-create",
                        "fifteen-detail-read",
                        "fifteen-detail-update",
                        "fifteen-detail-delete",
                        "fifteen-detail-export",
                        "fifteen-detail-export-excel",
                        "fifteen-detail-export-by-district",
                        "fifteen-detail-status"
                    ]
                }
            ]
        },
        {
            "id": 21,
            "name": "forest-survey",
            "label": "menuitems.forestSurvey.text",
            "icon": "mdi mdi-map-legend",
            "navigation": "/forest-survey",
            "roles": [1, 2, 7],
            "permissions": [
                "forest-survey-create",
                "forest-survey-read",
                "forest-survey-update",
                "forest-survey-delete",
                "forest-survey-export",
                "forest-survey-export-excel",
                "forest-survey-export-by-district",
                "forest-survey-status"
            ],
            "subItems": []
        },
        {
            "id": 19,
            "name": "forest-entry",
            "label": "menuitems.forest.text",
            "icon": "mdi mdi-grass",
            "navigation": "/forests",
            "roles": [1, 2, 7],
            "permissions": [
                "forest-entry-create",
                "forest-entry-read",
                "forest-entry-update",
                "forest-entry-delete",
                "forest-entry-export",
                "forest-entry-export-excel"
            ],
            "subItems": []
        },
        {
            "id": 22,
            "name": "plant-pd",
            "label": "menuitems.plantPd.text",
            "icon": "mdi mdi-barley",
            "navigation": "/plant-pd",
            "roles": [1, 2, 7,  8, 9, 10, 11, 12],
            "permissions": [
                "plant-pd-create",
                "plant-pd-read",
                "plant-pd-update",
                "plant-pd-delete",
                "plant-pd-export",
                "plant-pd-export-excel",
                "plant-pd-export-by-district",
                "plant-pd-status"
            ],
            "subItems": []
        },
        {
            "id": 20,
            "name": "wood-supply",
            "label": "menuitems.woodSupply.text",
            "icon": "mdi mdi-palm-tree",
            "navigation": "/wood-supply",
            "roles": [1, 2, 7,  8, 9, 10, 11, 12],
            "permissions": [
                "wood-supply-create",
                "wood-supply-read",
                "wood-supply-update",
                "wood-supply-delete",
                "wood-supply-export",
                "wood-supply-export-excel",
                "wood-supply-export-by-district",
                "wood-supply-status"
            ],
            "subItems": []
        },
        {
            "id": 26,
            "name": "jadibuti-list",
            "label": "menuitems.jadibutiList.text",
            "icon": "mdi mdi-flower-tulip",
            "navigation": "/jadibuti-list",
            "roles": [1, 2, 7,  8, 9, 10, 11, 12],
            "permissions": [
                "jadibuti-list-create",
                "jadibuti-list-read",
                "jadibuti-list-update",
                "jadibuti-list-delete"
            ],
            "subItems": []
        },
        {
            "id": 106,
            "name": "case-detail",
            "label": "menuitems.caseDetail.text",
            "icon": null,
            "navigation": "/case-detail",
            "roles": [1, 2, 7, 8, 9, 10, 11, 12],
            "permissions": [
                "case-detail-create",
                "case-detail-read",
                "case-detail-delete",
                "case-detail-update",
                "case-detail-export",
                "case-detail-export-excel",
                "case-detail-export-by-district",
                "case-detail-status"
            ],
            "subItems": []
        },
        {
            "id": 5,
            "name": "program-progress",
            "label": "menuitems.programProgress.text",
            "icon": "bx bx-list-ul",
            "navigation": "/program-progress",
            "roles": [1, 2, 7],
            "permissions": [
                "program-progress-create",
                "program-progress-read",
                "program-progress-update",
                "program-progress-delete",
                "program-progress-export"
            ],
            "subItems": []
        },
        {
            "id": 4,
            "name": "monthly-progress",
            "label": "menuitems.monthlyProgress.text",
            "icon": "mdi mdi-file-document-multiple",
            "navigation": "/monthly-progress",
            "roles": [1, 2, 7],
            "permissions": [
                "monthly-program-progress-create",
                "monthly-program-progress-read",
                "monthly-program-progress-update",
                "monthly-program-progress-export"
            ],
            "subItems": []
        },
        {
            "id": 7,
            "name": "user",
            "label": "menuitems.user.text",
            "icon": "mdi mdi-account-group-outline",
            "navigation": "/users",
            "roles": [1, 2, 7],
            "permissions": [
                "user-create",
                "user-read",
                "user-update",
                "user-delete"
            ],
            "subItems": []
        },
        {
            "id": 59,
            "name": "profile",
            "label": "menuitems.security.text",
            "icon": "bx bx-shield-alt-2",
            "navigation": "/security",
            "roles": [1, 2, 3, 4, 7,  8, 9, 10, 11, 12],
            "permissions": [
                "profile-update",
                "profile-read"
            ],
            "subItems": []
        },
        {
            "id": 6,
            "name": "role",
            "label": "menuitems.role.text",
            "icon": "mdi mdi-server-security",
            "navigation": "/roles",
            "roles": [1, 2, 7],
            "permissions": [
                "role-create",
                "role-read",
                "role-update",
                "role-delete"
            ],
            "subItems": []
        },
        {
            "id": 8,
            "name": "profile",
            "label": "menuitems.profile.text",
            "icon": "mdi mdi-account-box-outline",
            "navigation": "/profile",
            "roles": [1, 2, 3, 4, 7,  8, 9, 10, 11, 12],
            "permissions": [
                "profile-update",
                "profile-read"
            ],
            "subItems": []
        }
    ],
    months: [
        {label: 'साउन', value: 4},
        {label: 'भदौ', value: 5},
        {label: 'असोज', value: 6},
        {label: 'कार्तिक', value: 7},
        {label: 'मंसिर', value: 8},
        {label: 'पुष', value: 9},
        {label: 'माघ', value: 10},
        {label: 'फागुन', value: 11},
        {label: 'चैत', value: 12},
        {label: 'वैशाख', value: 1},
        {label: 'जेठ', value: 2},
        {label: 'असार', value: 3}
    ],
    percentage: [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100],
    showFormSearch: null,
}
const mutations = {
    locale(state, value) {
        state.locale = value
    },
    user(state, value) {
        state.user = value
    },
    showFullLoader(state, value) {
        state.showFullLoader = value
    },
    currentMonth(state, value) {
        state.currentMonth = value
    },
    currentFiscalYear(state, value) {
        state.currentFiscalYear = value
    },
    client_id(state, value) {
        state.client_id = value
    },
    logo(state, value) {
        state.logo = value
    },
    months(state, value) {
        state.months = value
    },
    percentage(state, value) {
        state.percentage = value
    },
    navigations(state, value) {
        state.navigations.push(value)
    },
    SET_NAVIGATION_DEFAULT(state, value) {
        state.navigations = value
    },
    SET_SHOW_FORM_SEARCH(state, value) {
        state.showFormSearch = value
    }
}
const getters = {
    locale: state => state.locale,
    user: state => state.user,
    showFullLoader: state => state.showFullLoader,
    currentMonth: state => state.currentMonth,
    currentFiscalYear: state => state.currentFiscalYear,
    client_id: state => state.client_id,
    logo: state => state.logo,
    months: state => state.months,
    percentage: state => state.percentage,
    navigations: state => state.navigations,
    showFormSearch: state => state.showFormSearch,
}
const actions = {
    getList(context, params) {
        if (context.getters.token) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + params.token
            return new Promise((resolve, reject) => {
                axios.get(`/pre-list`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    getNavigations(context, params) {
        if (context.getters.token) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + params.token
            return new Promise((resolve, reject) => {
                axios.get(`/navigation`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    domainCheck(context, params) {
        return new Promise((resolve, reject) => {
            axios.get(`/domain`)
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    updateProfile(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/profile/${data.username}?_method=PUT`, data.formData)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateSecurity(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/profile/security/${data.username}?_method=PUT`, data.form)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    }
}
export default {
    state,
    mutations,
    actions,
    getters
}
