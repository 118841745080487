import axios from "axios";

const state = {}
const mutations = {}
const getters = {}
const actions = {
    getTemplates(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/template`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    createTemplate(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/template/create`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    storeTemplate(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/template`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    showTemplate(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/template/${params.uid}`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    editTemplateByUid(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/template/${params.uid}/edit`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateTemplate(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/template/${data.uid}?_method=PUT`, data.form)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    deleteTemplate(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.delete(`/template/${params}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    getTemplateExportView(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/template-export`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateTemplateStatus(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/template-status/${data.uid}`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    resendForReviewTemplate(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/template-resend/${data.uid}/review`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    TemplateReport(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/template-report`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    importTemplate(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/template-import`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
}
export default {
    state,
    mutations,
    actions,
    getters
}
