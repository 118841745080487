/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

// require("./bootstrap");
import Vue from "vue";

window.Vue = Vue;

const axios = require('axios');
import {BootstrapVue} from "bootstrap-vue";
import vClickOutside from "v-click-outside";
import Vuelidate from "vuelidate";
import VueSweetalert2 from "vue-sweetalert2";
import i18n from "./i18n";
import VueApexCharts from 'vue-apexcharts'

import routes from './route'
import VueRouter from 'vue-router'
import {store} from './store/state';
import Vuex from 'vuex'

import CssBase from "css-loader/lib/css-base";
import AddStyles from "style-loader/lib/addStyles";

// require('./nepali.datepicker.v3.6.min')
// require('../sass/nepali.datepicker.v3.6.min.css')


Vue.use(BootstrapVue);
Vue.use(VueRouter);
Vue.use(vClickOutside);
Vue.use(Vuelidate);
Vue.use(VueSweetalert2);
Vue.use(Vuex);
Vue.use(VueApexCharts);

import VueAWN from "vue-awesome-notifications";

let options = {position: "top-right"}
Vue.use(VueAWN, options)
import 'vue-awesome-notifications/dist/styles/style.css';

import * as VueGoogleMaps from "vue2-google-maps";

Vue.use(VueGoogleMaps, {
    load: {
        key: "AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE",
        libraries: "places"
    },
    installComponents: true
});

Vue.component('apexchart', VueApexCharts)


Vue.filter('toCurrency', function (value) {
    if (typeof value !== "number") {
        return value;
    }
    var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'NPR',
        minimumFractionDigits: 0
    });
    return formatter.format(value);
});

Vue.http = Vue.prototype.$http = axios;
const token = store.getters.token;
// axios.defaults.headers.common['X-CSRF-TOKEN'] = jQuery('meta[name="csrf-token"]').attr('content');
if (token) {
    Vue.prototype.$http.defaults.headers.common['Authorization'] = 'Bearer ' + token
}

const router = new VueRouter({
    routes,
    mode: 'history',
    linkActiveClass: 'mm-active',
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return {x: 0, y: 0}
        }
    }
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters.token) {
            next()
        } else {
            next({
                name: 'login',
                query: {to: to ? to.path : null}
            })
        }
    } else if (to.matched.some(record => record.meta.requiresVisitor)) {
        if (store.getters.token) {
            next({name: 'dashboard'})
        } else {
            next()
        }
    } else {
        next()
    }
    document.title = to.meta.title ? to.meta.title : "DMIS"
    next()
})

import Layouts from "./mixins/layouts.mixin";
Vue.mixin({
    methods: {
        precise_round(n, r) {
            let int = Math.floor(n).toString()
            if (typeof n !== 'number' || typeof r !== 'number') return 'Not a Number'
            if (int[0] == '-' || int[0] == '+') int = int.slice(int[1], int.length)
            return n.toPrecision(int.length + r)
        },
    }
})

const app = new Vue({
    store,
    router,
    mixins: [Layouts],
    i18n,
    el: "#app",
});
