import axios from "axios";

const state = {}
const mutations = {}
const getters = {}
const actions = {
    getSarkariJadibutiChhodpurjis(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/sarkari-jadibuti-chhodpurji`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    createSarkariJadibutiChhodpurji(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/sarkari-jadibuti-chhodpurji/create`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    storeSarkariJadibutiChhodpurji(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/sarkari-jadibuti-chhodpurji`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    showSarkariJadibutiChhodpurji(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/sarkari-jadibuti-chhodpurji/${params.uid}`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    editSarkariJadibutiChhodpurjiByUid(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/sarkari-jadibuti-chhodpurji/${params.uid}/edit`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateSarkariJadibutiChhodpurji(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/sarkari-jadibuti-chhodpurji/${data.uid}?_method=PUT`, data.form)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    deleteSarkariJadibutiChhodpurji(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.delete(`/sarkari-jadibuti-chhodpurji/${params}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    getSarkariJadibutiChhodpurjiExportView(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/sarkari-jadibuti-chhodpurji-export`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateSarkariJadibutiChhodpurjiStatus(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/sarkari-jadibuti-chhodpurji-status/${data.uid}`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    resendForReviewSarkariJadibutiChhodpurji(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/sarkari-jadibuti-chhodpurji-resend/${data.uid}/review`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    sarkariJadibutiChhodpurjiReport(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/sarkari-jadibuti-chhodpurji-report`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
}
export default {
    state,
    mutations,
    actions,
    getters
}
