import Vue from 'vue'
import Vuex from 'vuex'
import modules from './modules'
const axios = require('axios');

axios.defaults.baseURL = '/api/';

Vue.use(Vuex);
export const store = new Vuex.Store({
    modules,
});
