import axios from "axios";

const state = {}
const mutations = {}
const getters = {}
const actions = {
    getFifteenDetails(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`fifteen-detail`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    createFifteenDetail(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`fifteen-detail/create`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    storeFifteenDetail(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`fifteen-detail`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    showFifteenDetail(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`fifteen-detail/${params.uid}`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    editFifteenDetailByUid(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`fifteen-detail/${params.uid}/edit`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateFifteenDetail(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`fifteen-detail/${data.uid}?_method=PUT`, data.form)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    deleteFifteenDetail(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.delete(`fifteen-detail/${params}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    getFifteenDetailExportView(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`fifteen-detail-export`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateFifteenDetailStatus(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`fifteen-detail-status/${data.uid}`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    resendForReviewFifteenDetail(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`fifteen-detail-resend/${data.uid}/review`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    fifteenDetailReport(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`fifteen-detail-report`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
}
export default {
    state,
    mutations,
    actions,
    getters
}
