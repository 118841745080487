import axios from "axios";

const state = {}
const mutations = {}
const getters = {}
const actions = {
    getTippanis(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/tippani`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    createTippani(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/tippani/create`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    storeTippani(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.defaults.headers.common['enctype'] = 'multipart/form-data';
                axios.post(`/tippani`, data, {headers: {'Content-Type': 'multipart/form-data'}})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    showTippaniBySlug(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/tippani/${data}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    editTippaniBySlug(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/tippani/${params.uid}/edit`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateTippani(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.defaults.headers.common['enctype'] = 'multipart/form-data';
                axios.post(`/tippani/${data.uid}?_method=PUT`, data.formData, {headers: {'Content-Type': 'multipart/form-data'}})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    storeTippaniForward(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/tippani-forward`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    verifyTippani(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/tippani-verify`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    getReviewOnTippaniBySlug(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/tippani/${data.uid}/review`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    storeReview(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/tippani/${data.uid}/reviewStore`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    searchForTippani(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/tippani-search`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    searchForStaffByDepartment(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/staff-department-search`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    getTippaniChats(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/tippani-chat?receiver_id=${data}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    storeTippaniChat(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/tippani-chat`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    deleteTippaniChat(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/tippani-chat-delete`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
}
export default {
    state,
    mutations,
    actions,
    getters
}
