import axios from "axios";

const state = {
    token: localStorage.getItem('token') || null,
    login_activity_id: localStorage.getItem('login_activity_id') || null,
}

const mutations = {
    token(state, token) {
        state.token = token
    },
    login_activity_id(state, token) {
        state.login_activity_id = token
    },
}
const getters = {
    token(state) {
        return state.token
    },
    login_activity_id(state) {
        return state.login_activity_id
    },
}

const actions = {
    login(context, credentials) {
        return new Promise((resolve, reject) => {
            axios.post('/login', {
                email: credentials.email,
                password: credentials.password,
            })
                .then(response => {
                    const token = response.data.token
                    const login_activity_id = response.data.login_activity_id
                    localStorage.setItem('token', token)
                    localStorage.setItem('login_activity_id', login_activity_id)
                    context.commit('token', token)
                    context.commit('login_activity_id', login_activity_id)
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    logout(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post('/logout', data)
                    .then(response => {
                        localStorage.removeItem('token')
                        context.commit('token', null);
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
}
export default {
    state,
    mutations,
    actions,
    getters
}
