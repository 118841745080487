import axios from "axios";

const state = {}
const mutations = {}
const getters = {}
const actions = {
    getNijiAabadis(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/niji-aabadi`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    createNijiAabadi(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/niji-aabadi/create`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    storeNijiAabadi(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/niji-aabadi`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    showNijiAabadi(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/niji-aabadi/${params.uid}`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    editNijiAabadiByUid(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/niji-aabadi/${params.uid}/edit`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateNijiAabadi(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/niji-aabadi/${data.uid}?_method=PUT`, data.form)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    deleteNijiAabadi(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.delete(`/niji-aabadi/${params}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    getNijiAabadiExportView(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/niji-aabadi-export`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateNijiAabadiStatus(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/niji-aabadi-status/${data.uid}`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    resendForReviewNijiAabadi(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/niji-aabadi-resend/${data.uid}/review`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    nijiAabadiReport(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/niji-aabadi-report`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    importNijiAabadi(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/niji-aabadi-import`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
}
export default {
    state,
    mutations,
    actions,
    getters
}
