import axios from "axios";

const state = {}
const mutations = {}
const getters = {}
const actions = {
    getLhfDetails(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/lhf-detail`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    createLhfDetail(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/lhf-detail/create`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    storeLhfDetail(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/lhf-detail`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    showLhfDetail(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/lhf-detail/${params.uid}`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    editLhfDetailByUid(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/lhf-detail/${params.uid}/edit`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateLhfDetail(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/lhf-detail/${data.uid}?_method=PUT`, data.form)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    deleteLhfDetail(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.delete(`/lhf-detail/${params}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    getLhfDetailExportView(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/lhf-detail-export`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateLhfDetailStatus(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/lhf-detail-status/${data.uid}`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    resendForReviewLhfDetail(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/lhf-detail-resend/${data.uid}/review`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    lhfDetailReport(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/lhf-detail-report`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
}
export default {
    state,
    mutations,
    actions,
    getters
}
