import axios from "axios";

const state = {}
const mutations = {}
const getters = {}
const actions = {
    getSarkariKathDauraChhodpurjis(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/sarkari-kath-daura-chhodpurji`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    createSarkariKathDauraChhodpurji(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/sarkari-kath-daura-chhodpurji/create`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    storeSarkariKathDauraChhodpurji(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/sarkari-kath-daura-chhodpurji`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    showSarkariKathDauraChhodpurji(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/sarkari-kath-daura-chhodpurji/${params.uid}`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    editSarkariKathDauraChhodpurjiByUid(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/sarkari-kath-daura-chhodpurji/${params.uid}/edit`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateSarkariKathDauraChhodpurji(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/sarkari-kath-daura-chhodpurji/${data.uid}?_method=PUT`, data.form)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    deleteSarkariKathDauraChhodpurji(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.delete(`/sarkari-kath-daura-chhodpurji/${params}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    getSarkariKathDauraChhodpurjiExportView(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/sarkari-kath-daura-chhodpurji-export`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateSarkariKathDauraChhodpurjiStatus(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/sarkari-kath-daura-chhodpurji-status/${data.uid}`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    resendForReviewSarkariKathDauraChhodpurji(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/sarkari-kath-daura-chhodpurji-resend/${data.uid}/review`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    sarkariKathDauraChhodpurjiReport(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/sarkari-kath-daura-chhodpurji-report`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
}
export default {
    state,
    mutations,
    actions,
    getters
}
