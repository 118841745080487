import axios from "axios";

const state = {}
const mutations = {}
const getters = {}
const actions = {
    getWoodSupplys(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/wood-supply`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    createWoodSupply(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/wood-supply/create`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    storeWoodSupply(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/wood-supply`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    showWoodSupply(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/wood-supply/${params.uid}`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    editWoodSupplyByUid(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/wood-supply/${params.uid}/edit`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateWoodSupply(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/wood-supply/${data.uid}?_method=PUT`, data.form)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    deleteWoodSupply(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.delete(`/wood-supply/${params}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    getWoodSupplyExportView(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/wood-supply-export`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateWoodSupplyStatus(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/wood-supply-status/${data.uid}`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    resendForReviewWoodSupply(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/wood-supply-resend/${data.uid}/review`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    woodSupplyReport(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/wood-supply-report`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    importWoodSupply(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/wood-supply-import`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
}
export default {
    state,
    mutations,
    actions,
    getters
}
