import axios from "axios";

const state = {}
const mutations = {}
const getters = {}
const actions = {
    getEmployeeDetails(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/employee-detail`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    createEmployeeDetail(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/employee-detail/create`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    storeEmployeeDetail(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/employee-detail`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    showEmployeeDetail(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/employee-detail/${params.uid}`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    editEmployeeDetailByUid(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/employee-detail/${params.uid}/edit`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateEmployeeDetail(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/employee-detail/${data.uid}?_method=PUT`, data.form)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    deleteEmployeeDetail(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.delete(`/employee-detail/${params}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    getEmployeeDetailExportView(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/employee-detail-export`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateEmployeeDetailStatus(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/employee-detail-status/${data.uid}`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    resendForReviewEmployeeDetail(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/employee-detail-resend/${data.uid}/review`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    employeeDetailReport(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/employee-detail-report`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    importEmployeeDetail(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/employee-detail-import`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
}
export default {
    state,
    mutations,
    actions,
    getters
}
