import axios from "axios";

const state = {}
const mutations = {}
const getters = {}
const actions = {
    getSamudayikJadibutiNikasis(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/samudayik-jadibuti-nikasi`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    createSamudayikJadibutiNikasi(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/samudayik-jadibuti-nikasi/create`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    storeSamudayikJadibutiNikasi(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/samudayik-jadibuti-nikasi`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    showSamudayikJadibutiNikasi(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/samudayik-jadibuti-nikasi/${params.uid}`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    editSamudayikJadibutiNikasiByUid(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/samudayik-jadibuti-nikasi/${params.uid}/edit`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateSamudayikJadibutiNikasi(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/samudayik-jadibuti-nikasi/${data.uid}?_method=PUT`, data.form)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    deleteSamudayikJadibutiNikasi(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.delete(`/samudayik-jadibuti-nikasi/${params}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    getSamudayikJadibutiNikasiExportView(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/samudayik-jadibuti-nikasi-export`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateSamudayikJadibutiNikasiStatus(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/samudayik-jadibuti-nikasi-status/${data.uid}`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    resendForReviewSamudayikJadibutiNikasi(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/samudayik-jadibuti-nikasi-resend/${data.uid}/review`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    samudayikJadibutiNikasiReport(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/samudayik-jadibuti-nikasi-report`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
}
export default {
    state,
    mutations,
    actions,
    getters
}
